import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Currency, CurrencyAmount, Rounding, Token } from '@dolomite-exchange/sdk-core'
import { Fraction, Pair, Price } from '@dolomite-exchange/v2-sdk'
import JSBI from 'jsbi'
import styled from 'styled-components/macro'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '../common/Tooltip'
import Input from '@material-ui/core/Input'
import ConfirmPoolModal from './ConfirmPoolModal'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { onAttemptToPermit, SignatureDataObject } from '../../hooks/usePermitOrApprove'
import { useDolomiteAmmRouterContract, usePairContract } from '../../hooks/useContract'
import { reqParseAmount, tryParseAmount } from '../../state/trade/hooks'
import { useActiveWeb3React } from '../../hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useDolomiteBalance, useTokenBalance } from '../../state/wallet/hooks'
import { useTotalSupply } from '../../data/TotalSupply'
import { useDepositIntoPool, useWithdrawFromPool } from '../../hooks/useAmmPool'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { calculateSlippageAmount, getPartial } from '../../utils'
import { BIG_INT_ZERO, FORMATTER, ZERO_FRACTION } from '../../constants'
import { useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import { PairState } from '../../data/Reserves'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { CloseIcon } from '../../theme'
import getDomainName from '../../utils/getDomainName'
import { StyledTooltip } from '../common/StyledTooltip'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { deriveMarketFromTokens } from '../../utils/marketUtils'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import { deserializeTokenOpt, serializeTokenOpt } from '../../hooks/Tokens'
import { formatAmount } from '../../utils/formatAmount'
import { useBlockTimestamp } from '../../state/chain/hooks'

const DEPOSIT = 0
const WITHDRAW = 1

const sanitizeErrorMessage = (errorMessage: string) => {
  return errorMessage.replace('transaction-rejected', 'Transaction rejected')
}

const DepositWithdrawExpanded = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? '300px' : '0')};
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
`

const DepositWithdrawPanelWrapper = styled.div`
  position: relative;
  background-color: #292938;
  display: inline-block;
  margin: 15px 0 0;
  padding: 10px 0 0;
  vertical-align: top;
  width: 100%;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => <Tab classes={{ selected: 'selected' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }
`

const Close = styled.div`
  position: absolute;
  display: inline-block;
  top: 14px;
  right: 0;
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  font-weight: 300;
  text-align: center;
`

const Fields = styled.div`
  margin: 20px 0;
`

const BalanceWrapper = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
`

const MaxButton = styled.div<{ selected: boolean }>`
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  vertical-align: top;

  &:hover {
    color: ${({ theme }) => theme.text1};
  }

  ${({ theme, selected }) => selected && `color: ${theme.text1} !important;`}
`

const Slider = styled.div<{ isOn: boolean }>`
  width: 24px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme, isOn }) => (isOn ? theme.bg5 : theme.bg3)};
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
`

const SliderDot = styled.div<{ isOn: boolean }>`
  width: 10px;
  height: 10px;
  margin: 2px 0 2px -10px;
  transition: transform 0.2s ease-in-out;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  ${({ isOn }) => isOn && 'transform: translateX(10px);'}
  background: ${({ theme }) => theme.bg2};
`

const InputWrapper = styled.div`
  position: relative;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  input:disabled {
    color: #606375 !important;
  }

  > div:first-child {
    margin-top: 3px;
    padding-top: 0;
  }

  > div:first-child > div {
    padding-top: 0 !important;
  }
`

const InsufficientBalanceWrapper = styled.div`
  color: ${({ theme }) => theme.red1};
  text-align: right;
  width: 100%;
  font-size: 14px;
  margin-bottom: -15px;
`

const SubmitWrapper = styled.div`
  margin-top: 20px;
`

const TotalWrapper = styled.div<{ wide: boolean }>`
  color: #d5d6e1;
  display: inline-block;
  font-size: 13px;
  font-weight: 100;
  margin-right: 8px;
  text-align: right;
  vertical-align: top;
  width: calc(100% - 97px);
  cursor: pointer;

  ${({ wide }) =>
    wide &&
    `
          width: calc(100% - 116px);
  `}
`

const Large = styled.div`
  color: #f9f9f9;
  font-size: 15px;
`

const Muted = styled.span`
  color: #606375;
`

// TODO disabled style
const SubmitButton = styled(Button)<{ wide?: boolean; unlock?: boolean }>`
  background-color: ${({ theme, unlock }) => (unlock ? theme.blue1 : theme.green2)} !important;
  display: inline-block;
  margin: 0 auto !important;
  vertical-align: top;
  width: 89px;
  color: #d5d6e1 !important;
  ${({ disabled }) => disabled && 'opacity: 0.8 !important;'}
  font-family: 'Open Sans', sans-serif !important;
  cursor: ${({ disabled }) => (disabled ? 'auto !important' : 'pointer !important')};
  pointer-events: ${({ disabled }) => (disabled ? 'none !important' : 'auto !important')};
  height: 36px !important;

  &:hover {
    background-color: ${({ theme, unlock }) => (unlock ? theme.blue2 : theme.green1)} !important;
  }

  > span > div {
    height: 20px !important;
    width: 20px !important;
  }

  svg {
    color: white !important;
  }

  ${({ wide }) =>
    wide &&
    `
    width: 108px;
  `}
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

export enum PoolTransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  UNLOCK = 'UNLOCK',
}

export interface PoolTransaction {
  pair: Pair | undefined
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  type: PoolTransactionType
  date: number
  isLoaded: boolean
  hash?: string
}

interface changedData {
  asset1: Token
  amount1: Fraction
  asset2: Token
  amount2: Fraction
}

interface Props {
  isOpen: boolean
  pair: Pair | undefined
  pairState: PairState
  onCollapse: () => void
  balanceInfo: (CurrencyAmount<Currency> | null)[]
  setIsTxLoading: (loading: boolean) => void
  setPendingHash: (hash: string | undefined) => void
  recentTransactions: PoolTransaction[]
  setTransactions: (recentTransactions: PoolTransaction[]) => void
  setDepositAmounts: (newAmounts: changedData | undefined) => void
  setWithdrawAmounts: (newAmounts: changedData | undefined) => void
}

function PoolDepositWithdrawPanelComparator(prev: Props, next: Props) {
  return (
    prev.isOpen === next.isOpen &&
    prev.pair === next.pair &&
    prev.pairState === next.pairState &&
    prev.onCollapse === next.onCollapse &&
    prev.balanceInfo === next.balanceInfo &&
    prev.recentTransactions === next.recentTransactions &&
    prev.setDepositAmounts === next.setDepositAmounts &&
    prev.setWithdrawAmounts === next.setWithdrawAmounts
  )
}

function PoolDepositWithdrawPanel({
  isOpen,
  pair,
  pairState,
  onCollapse,
  balanceInfo,
  setIsTxLoading,
  setPendingHash,
  recentTransactions,
  setTransactions,
  setDepositAmounts,
  setWithdrawAmounts,
}: Props) {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(DEPOSIT)
  const [inputValue0, setInputValue0] = useState<string>('')
  const [inputValue1, setInputValue1] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInverse, setIsInverse] = useState<boolean>(false)
  const [isWithdrawingAll, setIsWithdrawingAll] = useState<boolean>(false)
  const [isAwaitingSignature, setIsAwaitingSignature] = useState<boolean>(false)
  const [token0HasSufficientBalance, setToken0HasSufficientBalance] = useState<boolean>(false)
  const [token1HasSufficientBalance, setToken1HasSufficientBalance] = useState<boolean>(false)
  const allOptions = [t('deposit'), t('withdraw')]
  const dolomiteAmmRouterContract = useDolomiteAmmRouterContract()
  const web3Context = useActiveWeb3React()
  const { account, chainId } = web3Context
  const deadline = useTransactionDeadline()
  const isArgentWallet = useIsArgentWallet()
  const pairContract = usePairContract(pair?.liquidityToken.address)
  const [activeTransactionHash, setActiveTransactionHash] = useState<string | undefined>(undefined)
  const isTransactionPending = useIsTransactionPending(activeTransactionHash)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [allowedSlippage] = useUserSlippageTolerance()
  const [activeInput, setActiveInput] = useState<number | undefined>(undefined)
  const prevReserve0Ref = useRef<CurrencyAmount<Token>>()
  const prevReserve1Ref = useRef<CurrencyAmount<Token>>()
  useEffect(() => {
    if (pair?.reserve0 && pair?.reserve1) {
      prevReserve0Ref.current = pair.reserve0
      prevReserve1Ref.current = pair.reserve1
    }
  })

  const prevReserve0 = prevReserve0Ref.current
  const prevReserve1 = prevReserve1Ref.current

  useEffect(() => {
    if (activeTransactionHash && !isTransactionPending) {
      setIsAwaitingSignature(false)
      setIsLoading(false)
      setActiveTransactionHash(undefined)
      setIsWithdrawingAll(false)
    }
  }, [isTransactionPending, activeTransactionHash])
  useEffect(() => {
    //setDepositAmounts(undefined)
    //setWithdrawAmounts(undefined)
  }, [pairState, setDepositAmounts, setWithdrawAmounts])
  const [signatureData, setSignatureData] = useState<SignatureDataObject | null>(null)

  const userLiquidityTokenBalance = useTokenBalance(account, pair?.liquidityToken)
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const serializedToken0 = useMemo(() => serializeTokenOpt(pair?.token0), [pair?.token0])
  const serializedToken1 = useMemo(() => serializeTokenOpt(pair?.token1), [pair?.token1])
  const token0 = useMemo(() => deserializeTokenOpt(serializedToken0), [serializedToken0])
  const token1 = useMemo(() => deserializeTokenOpt(serializedToken1), [serializedToken1])

  const inputTokenAmount0 = useMemo(() => tryParseAmount(inputValue0, token0), [inputValue0, token0])
  const inputTokenAmount1 = useMemo(() => tryParseAmount(inputValue1, token1), [inputValue1, token1])
  const rate = useMemo(() => {
    return inputTokenAmount0?.asFraction.divide(
      inputTokenAmount1?.greaterThan('0') ? inputTokenAmount1.asFraction : '1',
    )
  }, [inputTokenAmount0, inputTokenAmount1])
  const market = useMemo(() => {
    return deriveMarketFromTokens(token0, token1)
  }, [token0, token1])
  const rawPrice = useMemo(() => {
    if (!rate || rate.equalTo(ZERO_FRACTION) || !token0 || !token1) {
      return undefined
    }

    return new Price({
      baseAmount: reqParseAmount(rate.toFixed(token0.decimals), token0),
      quoteAmount: reqParseAmount('1', token1),
    })
  }, [rate, token0, token1])

  const reservePrice = useFormattedPrice(rawPrice, market)

  const walletBalance0 = useDolomiteBalance(account, token0)
  const walletBalance1 = useDolomiteBalance(account, token1)

  const [lpBalance0, lpBalance1] = useMemo(() => {
    if (userLiquidityTokenBalance && pair && totalSupply) {
      return [
        getPartial(pair.reserve0, userLiquidityTokenBalance, totalSupply),
        getPartial(pair.reserve1, userLiquidityTokenBalance, totalSupply),
      ]
    }
    return [undefined, undefined]
  }, [pair, totalSupply, userLiquidityTokenBalance])

  const liquidityTokenAmount = useMemo(() => {
    if (pair && inputTokenAmount0 && totalSupply) {
      if (isWithdrawingAll) {
        return userLiquidityTokenBalance
      } else if (pair.reserve0.greaterThan(ZERO_FRACTION)) {
        const amount = CurrencyAmount.fromFractionalAmount(
          pair.liquidityToken,
          totalSupply.multiply(inputTokenAmount0).quotient,
          pair.reserve0.quotient,
        )
        if (userLiquidityTokenBalance && amount.greaterThanOrEqual(userLiquidityTokenBalance)) {
          return userLiquidityTokenBalance
        } else {
          return amount
        }
      } else {
        return CurrencyAmount.fromRawAmount(pair.liquidityToken, '0')
      }
    }

    return undefined
  }, [inputTokenAmount0, isWithdrawingAll, pair, totalSupply, userLiquidityTokenBalance])

  useEffect(() => {
    if (selectedTab === DEPOSIT) {
      if (inputTokenAmount0 && inputTokenAmount1 && walletBalance0 && walletBalance1) {
        setToken0HasSufficientBalance(JSBI.lessThanOrEqual(inputTokenAmount0.quotient, walletBalance0.quotient))
        setToken1HasSufficientBalance(JSBI.lessThanOrEqual(inputTokenAmount1.quotient, walletBalance1.quotient))
      }
    } else if (selectedTab === WITHDRAW) {
      if (inputTokenAmount0 && inputTokenAmount1 && lpBalance0 && lpBalance1) {
        setToken0HasSufficientBalance(
          isWithdrawingAll || JSBI.lessThanOrEqual(inputTokenAmount0.quotient, lpBalance0.quotient),
        )
        setToken1HasSufficientBalance(
          isWithdrawingAll || JSBI.lessThanOrEqual(inputTokenAmount1.quotient, lpBalance1.quotient),
        )
      }
    }
  }, [
    selectedTab,
    isWithdrawingAll,
    inputTokenAmount0,
    inputTokenAmount1,
    walletBalance0,
    walletBalance1,
    lpBalance0,
    lpBalance1,
  ])

  const blockTimestamp = useBlockTimestamp()
  useEffect(() => {
    const buffer = 15
    if (signatureData && blockTimestamp && blockTimestamp.gte(signatureData.deadline - buffer)) {
      // signature expired
      setSignatureData(null)
    }
  }, [blockTimestamp, signatureData])

  const [slippageTolerance] = useUserSlippageTolerance()
  const [inputValue0Fiat] = useFiatValueWithLoadingIndicator(inputTokenAmount0, token0)
  const [inputValue1Fiat] = useFiatValueWithLoadingIndicator(inputTokenAmount1, token1)

  const [amount0Min, amount1Min] = useMemo(() => {
    if (!pair || !token0 || !token1 || !liquidityTokenAmount || !totalSupply) {
      return [undefined, undefined]
    }

    const amount0 = getPartial(pair.reserve0, liquidityTokenAmount, totalSupply)
    const amount1 = getPartial(pair.reserve1, liquidityTokenAmount, totalSupply)

    const [amount0LowerBigInt] = calculateSlippageAmount(amount0, slippageTolerance)
    const [amount1LowerBigInt] = calculateSlippageAmount(amount1, slippageTolerance)

    return [
      CurrencyAmount.fromRawAmount(token0, amount0LowerBigInt),
      CurrencyAmount.fromRawAmount(token1, amount1LowerBigInt),
    ]
  }, [pair, token0, token1, liquidityTokenAmount, totalSupply, slippageTolerance])

  const [approvalState, approveCallback] = useApproveCallback(liquidityTokenAmount, dolomiteAmmRouterContract?.address)

  const { callback: onWithdrawFromPool, error: withdrawHookError } = useWithdrawFromPool(
    approvalState,
    signatureData,
    token0?.address,
    token1?.address,
    liquidityTokenAmount,
    amount0Min,
    amount1Min,
  )

  const { callback: onDepositIntoPool, error: depositHookError } = useDepositIntoPool(
    token0?.address,
    token1?.address,
    inputTokenAmount0,
    inputTokenAmount1,
    slippageTolerance,
  )

  const approvalRequired = selectedTab === WITHDRAW && !signatureData && approvalState !== ApprovalState.APPROVED

  const submitWithdraw = useCallback(() => {
    if (approvalState !== ApprovalState.APPROVED && !signatureData) {
      console.error('Withdrawal not approved and not permitted')
      return
    }

    if (!onWithdrawFromPool) {
      console.error(
        'onWithdrawFromPool is null. This only happens when the user was able to withdraw but should not have',
        withdrawHookError,
      )
      return
    }

    setIsLoading(true)
    setIsTxLoading(true)
    setIsAwaitingSignature(true)
    setIsAttemptingTx(true)
    setErrorMessage(undefined)
    const newTransaction = {
      pair: pair,
      amount0: inputTokenAmount0,
      amount1: inputTokenAmount1,
      type: PoolTransactionType.WITHDRAW,
      date: Date.now(),
      isLoaded: false,
      chainId: chainId,
      hash: undefined,
    }
    const activeTransaction = recentTransactions.length
    const preparedTransactions = recentTransactions.concat(newTransaction).map(transaction => {
      return {
        ...transaction,
        numerator0: transaction.amount0?.numerator.toString(),
        denominator0: transaction.amount0?.denominator.toString(),
        numerator1: transaction.amount1?.numerator.toString(),
        denominator1: transaction.amount1?.denominator.toString(),
      }
    })
    localStorage.setItem(
      'recent_pool_transactions',
      JSON.stringify({
        account: account?.toString(),
        data: preparedTransactions,
      }),
    )
    setTransactions(recentTransactions.concat(newTransaction))

    onWithdrawFromPool()
      .then(hash => {
        setTxHash(hash)
        setIsAttemptingTx(false)
        setInputValue0('')
        setInputValue1('')
        setDepositAmounts(undefined)
        setWithdrawAmounts(undefined)
        preparedTransactions[preparedTransactions.length - 1].hash = hash
        setTransactions(preparedTransactions)
        localStorage.setItem(
          'recent_pool_transactions',
          JSON.stringify({
            account: account?.toString(),
            data: preparedTransactions,
          }),
        )
        setPendingHash(hash)
        setActiveTransactionHash(hash)
      })
      .catch((error: Error) => {
        setIsAttemptingTx(false)
        setErrorMessage(sanitizeErrorMessage(error.message))
        const preparedTransactions = recentTransactions
          .slice()
          .splice(activeTransaction, 1)
          .map(transaction => {
            return {
              ...transaction,
              numerator0: transaction.amount0?.numerator.toString(),
              denominator0: transaction.amount0?.denominator.toString(),
              numerator1: transaction.amount1?.numerator.toString(),
              denominator1: transaction.amount1?.denominator.toString(),
            }
          })
        localStorage.setItem(
          'recent_pool_transactions',
          JSON.stringify({
            account: account?.toString(),
            data: preparedTransactions,
          }),
        )
        setTransactions(recentTransactions)
        setIsAwaitingSignature(false)
        setIsLoading(false)
        setIsTxLoading(false)
        if (error.message !== 'transaction-rejected') {
          console.error('Could not withdraw due to error ', error)
        }
      })
  }, [
    account,
    approvalState,
    chainId,
    inputTokenAmount0,
    inputTokenAmount1,
    onWithdrawFromPool,
    pair,
    recentTransactions,
    setDepositAmounts,
    setIsTxLoading,
    setPendingHash,
    setTransactions,
    setWithdrawAmounts,
    signatureData,
    withdrawHookError,
  ])
  const submitDeposit = useCallback(() => {
    if (!onDepositIntoPool) {
      console.error(
        'onDepositIntoPool is null. This only happens when the user was able to deposit but should not have',
        depositHookError,
      )
      return
    }

    setIsLoading(true)
    setIsTxLoading(true)
    setIsAwaitingSignature(true)
    setIsAttemptingTx(true)
    setErrorMessage(undefined)
    const newTransaction = {
      pair: pair,
      amount0: inputTokenAmount0,
      amount1: inputTokenAmount1,
      type: PoolTransactionType.DEPOSIT,
      date: Date.now(),
      isLoaded: false,
      chainId: chainId,
      hash: undefined,
    }
    const activeTransaction = recentTransactions.length
    const preparedTransactions = recentTransactions.concat(newTransaction).map(transaction => {
      return {
        ...transaction,
        numerator0: transaction.amount0?.numerator.toString(),
        denominator0: transaction.amount0?.denominator.toString(),
        numerator1: transaction.amount1?.numerator.toString(),
        denominator1: transaction.amount1?.denominator.toString(),
      }
    })
    localStorage.setItem(
      'recent_pool_transactions',
      JSON.stringify({
        account: account?.toString(),
        data: preparedTransactions,
      }),
    )
    setTransactions(recentTransactions.concat(newTransaction))

    onDepositIntoPool()
      .then(hash => {
        setTxHash(hash)
        setIsAttemptingTx(false)
        setInputValue0('')
        setInputValue1('')
        setDepositAmounts(undefined)
        setWithdrawAmounts(undefined)
        preparedTransactions[preparedTransactions.length - 1].hash = hash
        setTransactions(preparedTransactions)
        localStorage.setItem(
          'recent_pool_transactions',
          JSON.stringify({
            account: account?.toString(),
            data: preparedTransactions,
          }),
        )
        setPendingHash(hash)
        setActiveTransactionHash(hash)
      })
      .catch((error: Error) => {
        setIsAttemptingTx(false)
        setErrorMessage(sanitizeErrorMessage(error.message))
        const preparedTransactions = recentTransactions
          .slice()
          .splice(activeTransaction, 1)
          .map(transaction => {
            return {
              ...transaction,
              numerator0: transaction.amount0?.numerator.toString(),
              denominator0: transaction.amount0?.denominator.toString(),
              numerator1: transaction.amount1?.numerator.toString(),
              denominator1: transaction.amount1?.denominator.toString(),
            }
          })
        localStorage.setItem(
          'recent_pool_transactions',
          JSON.stringify({
            account: account?.toString(),
            data: preparedTransactions,
          }),
        )
        setTransactions(recentTransactions)
        setIsAwaitingSignature(false)
        setIsLoading(false)
        setIsTxLoading(false)
        if (error.message !== 'transaction-rejected') {
          console.error('Could not deposit due to error ', error)
        }
      })
  }, [
    account,
    chainId,
    depositHookError,
    inputTokenAmount0,
    inputTokenAmount1,
    onDepositIntoPool,
    pair,
    recentTransactions,
    setDepositAmounts,
    setIsTxLoading,
    setPendingHash,
    setTransactions,
    setWithdrawAmounts,
  ])
  const changeValue = useCallback(
    (value: string, isInputValue0: boolean) => {
      if (value === '') {
        setInputValue0('')
        setInputValue1('')
        setActiveInput(undefined)
      }

      const valueAmount = tryParseAmount(value, isInputValue0 ? token0 : token1)
      if (pair && valueAmount) {
        const roundingMode = selectedTab === WITHDRAW ? Rounding.ROUND_HALF_UP : undefined
        if (isInputValue0) {
          setInputValue0(value)
          if (activeInput === undefined || activeInput === 1) {
            setActiveInput(0)
          }

          if (!pair.reserve0.equalTo('0')) {
            const amount1 = pair.reserve1.multiply(valueAmount.quotient).divide(pair.reserve0.quotient)
            setInputValue1(amount1.toFixed(pair.token1.decimals, undefined, roundingMode))
            selectedTab === DEPOSIT
              ? setDepositAmounts({
                  asset1: pair.reserve0.currency,
                  amount1: valueAmount.asFraction,
                  asset2: pair.reserve1.currency,
                  amount2: amount1.asFraction,
                })
              : setWithdrawAmounts({
                  asset1: pair.reserve0.currency,
                  amount1: valueAmount.asFraction,
                  asset2: pair.reserve1.currency,
                  amount2: amount1.asFraction,
                })
          }
        } else {
          setInputValue1(value)
          if (activeInput === undefined || activeInput === 0) {
            setActiveInput(1)
          }

          if (!pair.reserve1.equalTo('0') && token0) {
            const amount0 = pair.reserve0.multiply(valueAmount.quotient).divide(pair.reserve1.quotient)
            setInputValue0(amount0.toFixed(token0.decimals, undefined, roundingMode))
            selectedTab === DEPOSIT
              ? setDepositAmounts({
                  asset1: pair.reserve0.currency,
                  amount1: amount0.asFraction,
                  asset2: pair.reserve1.currency,
                  amount2: valueAmount.asFraction,
                })
              : setWithdrawAmounts({
                  asset1: pair.reserve0.currency,
                  amount1: amount0.asFraction,
                  asset2: pair.reserve1.currency,
                  amount2: valueAmount.asFraction,
                })
          } else {
            setDepositAmounts(undefined)
            setWithdrawAmounts(undefined)
          }
        }
      } else {
        setDepositAmounts(undefined)
        setWithdrawAmounts(undefined)
      }
    },
    [activeInput, pair, selectedTab, setDepositAmounts, setWithdrawAmounts, token0, token1],
  )

  useEffect(() => {
    if (liquidityTokenAmount && signatureData && liquidityTokenAmount.greaterThan(signatureData.amount)) {
      setSignatureData(null)
    }
    if (prevReserve0 !== pair?.reserve0 || prevReserve1 !== pair?.reserve1) {
      if (activeInput === 0) {
        changeValue(inputValue0, true)
      } else {
        changeValue(inputValue1, false)
      }
    }
  }, [
    activeInput,
    changeValue,
    inputValue0,
    inputValue1,
    liquidityTokenAmount,
    pair?.reserve0,
    pair?.reserve1,
    prevReserve0,
    prevReserve1,
    signatureData,
  ])

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>, isInputValue0: boolean) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      changeValue(e.target.value, isInputValue0)
    }
  }

  const updateSelectedTab = (newSelectedTab: number) => {
    if (newSelectedTab !== selectedTab) {
      setSelectedTab(newSelectedTab)
      setInputValue0('')
      setInputValue1('')
      setIsWithdrawingAll(false)
      setSignatureData(null)
      setDepositAmounts(undefined)
      setWithdrawAmounts(undefined)
    }
  }

  const options = allOptions

  const onClickMax = (isInputValueA: boolean) => {
    const balance0 = selectedTab === DEPOSIT ? walletBalance0 : lpBalance0
    const balance1 = selectedTab === DEPOSIT ? walletBalance1 : lpBalance1
    const balance = isInputValueA ? balance0 : balance1
    if (balance?.greaterThan('0')) {
      const roundingMode = selectedTab === WITHDRAW ? Rounding.ROUND_HALF_UP : Rounding.ROUND_DOWN
      changeValue(balance.toFixed(6, undefined, roundingMode), isInputValueA)
      if (selectedTab === WITHDRAW) {
        if (isWithdrawingAll) {
          // Clear the input
          setInputValue0('')
          setInputValue1('')
        }
        setIsWithdrawingAll(!isWithdrawingAll)
      }
    }
  }

  const openDialog = () => {
    setIsAttemptingTx(false)
    setErrorMessage(undefined)
    setIsDialogOpen(true)
    setTxHash(undefined)
  }

  // Additional amount to unlock on top of the exact amount needed to allow for movements in price between unlocking and submitting the withdrawal
  const slippageBuffer = useMemo(() => {
    return new Fraction(JSBI.BigInt(allowedSlippage), JSBI.BigInt(10000)).divide(10).add(1)
  }, [allowedSlippage])

  const onClickUnlock = useCallback(() => {
    if (!dolomiteAmmRouterContract?.address || !pairContract || !web3Context || !deadline) {
      return
    }
    setIsDialogOpen(false)
    setIsAttemptingTx(true)
    setIsLoading(true)
    setIsTxLoading(true)
    setIsAwaitingSignature(true)
    setErrorMessage(undefined)

    let amount = liquidityTokenAmount?.multiply(slippageBuffer)
    if (userLiquidityTokenBalance && amount?.greaterThan(userLiquidityTokenBalance)) {
      amount = userLiquidityTokenBalance
    }
    onAttemptToPermit(
      amount,
      dolomiteAmmRouterContract.address,
      web3Context,
      deadline,
      pairContract,
      getDomainName(pair, web3Context.chainId),
      isArgentWallet,
      approveCallback,
      setSignatureData,
    )
      .then(signatureObject => {
        if (signatureObject) {
          setPendingHash(undefined)
          setActiveTransactionHash(undefined)
        }
        setIsLoading(false)
        setIsTxLoading(false)
        setIsAwaitingSignature(false)
        setIsAttemptingTx(false)
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Could not approve due to error', error.message, error)
        setIsAttemptingTx(false)
        setErrorMessage(sanitizeErrorMessage(error.message))
        setIsLoading(false)
        setIsTxLoading(false)
        setIsAwaitingSignature(false)
      })
  }, [
    approveCallback,
    deadline,
    dolomiteAmmRouterContract,
    isArgentWallet,
    liquidityTokenAmount,
    pair,
    pairContract,
    setIsTxLoading,
    setPendingHash,
    slippageBuffer,
    userLiquidityTokenBalance,
    web3Context,
  ])

  const onDismiss = useCallback(() => setIsDialogOpen(false), [])
  const onConfirm = useCallback(() => {
    if (approvalRequired) {
      onClickUnlock()
    } else if (selectedTab === DEPOSIT) {
      submitDeposit()
    } else {
      submitWithdraw()
    }
  }, [approvalRequired, onClickUnlock, selectedTab, submitDeposit, submitWithdraw])

  return (
    <DepositWithdrawExpanded expanded={isOpen}>
      <ConfirmPoolModal
        pair={pair}
        isOpen={isDialogOpen}
        amount0={inputTokenAmount0}
        amount1={inputTokenAmount1}
        fiatAmount0={inputValue0Fiat}
        fiatAmount1={inputValue1Fiat}
        attemptingTxn={isAttemptingTx}
        txHash={txHash}
        deposit={selectedTab === DEPOSIT}
        unlock={approvalRequired}
        allowedSlippage={allowedSlippage}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        errorMessage={errorMessage}
      />
      <DepositWithdrawPanelWrapper>
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => updateSelectedTab(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(options ?? []).map((option: string, index: number) => (
            <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
          ))}
        </StyledTabs>
        <Close>
          <CloseIcon onClick={onCollapse} />
        </Close>
        {pairState !== PairState.EXISTS && (
          <TopRow>
            <span>This pool has not been created yet. Inputting an amount dictates the initial price</span>
          </TopRow>
        )}
        <div>
          <Fields>
            {token0 && token1 ? (
              [token0, token1].map((value: Currency, index: number) => {
                const walletBalances = [walletBalance0, walletBalance1]
                const lpBalances = [lpBalance0, lpBalance1]
                const displayBalance =
                  selectedTab === DEPOSIT
                    ? walletBalances[index]?.toFixed(6, FORMATTER) ?? '0.00'
                    : lpBalances[index]?.toFixed(6, FORMATTER, Rounding.ROUND_HALF_UP) ?? '0.00'

                const displayValue = [inputValue0, inputValue1][index]
                return (
                  <div key={`${options[index]}-${value.symbol}`}>
                    <TopRow>
                      <BalanceWrapper>
                        {t('balance', { balanceInput: displayBalance })}&nbsp;{cleanCurrencySymbol(value)}
                      </BalanceWrapper>
                      {index === 1 && selectedTab === WITHDRAW ? (
                        <div />
                      ) : (
                        <MaxButton
                          selected={selectedTab === WITHDRAW && isWithdrawingAll}
                          onClick={() => onClickMax(index === 0)}
                        >
                          Max{' '}
                          {selectedTab === WITHDRAW && (
                            <Slider isOn={isWithdrawingAll}>
                              <SliderDot isOn={isWithdrawingAll} />
                            </Slider>
                          )}
                        </MaxButton>
                      )}
                    </TopRow>
                    <InputWrapper>
                      <StyledInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e, !index)}
                        multiline={false}
                        fullWidth
                        spellCheck={false}
                        placeholder={'0.00'}
                        value={displayValue}
                        variant={'amountInput'}
                        disableUnderline={true}
                        endAdornment={''}
                        disabled={isWithdrawingAll}
                      />
                    </InputWrapper>
                  </div>
                )
              })
            ) : (
              <div />
            )}
          </Fields>
          {inputValue0 && inputValue1 && !(token0HasSufficientBalance && token1HasSufficientBalance) && (
            <InsufficientBalanceWrapper>
              Insufficient{!token0HasSufficientBalance && ` ${cleanCurrencySymbol(inputTokenAmount0?.currency)}`}
              {!(token0HasSufficientBalance || token1HasSufficientBalance) && ` and`}
              {!token1HasSufficientBalance && ` ${cleanCurrencySymbol(inputTokenAmount1?.currency)}`} Balance
            </InsufficientBalanceWrapper>
          )}
          {account !== undefined && (
            <SubmitWrapper>
              <TotalWrapper
                onClick={() => setIsInverse(!isInverse)}
                wide={selectedTab === WITHDRAW && !approvalRequired}
              >
                <Large>
                  {formatAmount(isInverse ? reservePrice?.invert() : reservePrice, undefined, true, '0.00')}
                  &nbsp;
                  {isInverse ? cleanCurrencySymbol(market?.primaryToken) : cleanCurrencySymbol(market?.secondaryToken)}
                  &nbsp;per&nbsp;
                  {isInverse ? cleanCurrencySymbol(market?.secondaryToken) : cleanCurrencySymbol(market?.primaryToken)}
                </Large>
                <div>
                  <Muted>
                    ~${formatAmount(inputValue0Fiat?.add(inputValue1Fiat ?? '0'), undefined, true, '0.00')}
                    &nbsp;total
                  </Muted>
                </div>
              </TotalWrapper>
              {isLoading ? (
                <StyledTooltip
                  title={`${
                    isAwaitingSignature
                      ? t('awaitingSignature')
                      : approvalRequired
                      ? t('unlocking')
                      : selectedTab === DEPOSIT
                      ? t('depositing')
                      : t('withdrawing')
                  }...`}
                  placement={'top'}
                >
                  <SubmitButton wide={selectedTab === WITHDRAW && !approvalRequired} unlock={approvalRequired}>
                    <CircularProgress />
                  </SubmitButton>
                </StyledTooltip>
              ) : balanceInfo ? (
                !approvalRequired &&
                !(selectedTab === WITHDRAW && (!inputTokenAmount0 || inputTokenAmount0.equalTo(BIG_INT_ZERO))) ? (
                  <StyledTooltip
                    title={t('invalidInput')}
                    placement={'top'}
                    hideTooltip={
                      !(
                        !inputTokenAmount0 ||
                        inputTokenAmount0.equalTo(BIG_INT_ZERO) ||
                        !(token0HasSufficientBalance && token1HasSufficientBalance)
                      )
                    }
                  >
                    <SubmitButton
                      wide={selectedTab === WITHDRAW}
                      disabled={
                        !inputTokenAmount0 ||
                        inputTokenAmount0.equalTo(BIG_INT_ZERO) ||
                        !(token0HasSufficientBalance && token1HasSufficientBalance)
                      }
                      onClick={openDialog}
                    >
                      <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
                    </SubmitButton>
                  </StyledTooltip>
                ) : (
                  <Tooltip
                    title={
                      !liquidityTokenAmount ||
                      JSBI.equal(liquidityTokenAmount.quotient, BIG_INT_ZERO) ||
                      !inputTokenAmount0 ||
                      inputTokenAmount0.equalTo(BIG_INT_ZERO)
                        ? t('invalidInput')
                        : t('approvalRequired')
                    }
                  >
                    <SubmitButton
                      onClick={onClickUnlock}
                      disabled={
                        !liquidityTokenAmount ||
                        liquidityTokenAmount.equalTo(ZERO_FRACTION) ||
                        !inputTokenAmount0 ||
                        inputTokenAmount0.equalTo(ZERO_FRACTION)
                      }
                      unlock={true}
                    >
                      <span>{t('unlock')}</span>
                    </SubmitButton>
                  </Tooltip>
                )
              ) : (
                <SubmitButton wide={selectedTab === WITHDRAW} disabled={true}>
                  <span>{selectedTab === DEPOSIT ? t('deposit') : t('withdraw')}</span>
                </SubmitButton>
              )}
            </SubmitWrapper>
          )}
        </div>
      </DepositWithdrawPanelWrapper>
    </DepositWithdrawExpanded>
  )
}

export default React.memo(PoolDepositWithdrawPanel, PoolDepositWithdrawPanelComparator)
