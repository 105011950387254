import { BaseProvider, Web3Provider } from '@ethersproject/providers'
import { Dispatch, useCallback, useMemo } from 'react'
import { CHAIN_ID_TO_URLS_MAP, LIBRARY_MAP } from '../connectors'
import { ChainId, USER_ERROR_MESSAGES } from '../constants'
import { Contract } from '@ethersproject/contracts'
import { BigNumber } from 'ethers'
import { ContractCallParameters } from '@dolomite-exchange/v2-sdk'
import { useConnectWallet, useSetChain as useSetChainViaSdk } from '@web3-onboard/react'
import { ConnectOptions, DisconnectOptions, WalletState } from '@web3-onboard/core'
import { getAddress } from '@ethersproject/address'
import { useChainId, useIsSettingChainId } from '../state/chain/hooks'
import { useDispatch } from 'react-redux'
import { updateChainId, updateIsSettingChainId } from '../state/chain/actions'
import { NETWORK_LABELS, NETWORK_LOGOS } from '../constants/chainId'
import { getEtherscanLink } from '../utils'
import store from '../state'
import { Ether } from '@dolomite-exchange/sdk-core'

export function useValidChain(): [ChainId | undefined, Error | undefined] {
  const [{ chains, connectedChain }] = useSetChainViaSdk()
  return useMemo(() => {
    if (connectedChain) {
      const isValid = chains.some(chain => chain.id === connectedChain.id)
      if (isValid && connectedChain.id !== `0x${ChainId.MAINNET.toString(16)}`) {
        return [parseInt(connectedChain.id.substring(2), 16) as ChainId, undefined]
      } else {
        return [undefined, new Error(INVALID_CHAIN)]
      }
    }
    return [undefined, undefined]
  }, [chains, connectedChain])
}

export function useWeb3ChainId(): ChainId {
  const currentChainId = useChainId()
  return useValidChain()[0] ?? currentChainId
}

export interface MinimalWeb3Context {
  library: BaseProvider | Web3Provider
  chainId: ChainId
}

export interface DolomiteWeb3Context extends MinimalWeb3Context {
  account?: string
  wallet: WalletState | null
  active: boolean
  error?: Error
  connect: (options?: ConnectOptions) => Promise<WalletState[]>
  disconnect: (wallet: DisconnectOptions) => Promise<WalletState[]>
  isSettingChain: boolean
  setChain: (chainId: ChainId) => Promise<boolean>
}

export const INVALID_CHAIN = 'INVALID_CHAIN'
const UNRECOGNIZED_CHAIN_ID = 4902
const CANCELLED_REQUEST = 4001

function parseCodeFromError(e: any): number | undefined {
  const foundCode = e.data?.originalError?.code?.toString() ?? e.code?.toString()
  if (e.message && foundCode === undefined) {
    try {
      return JSON.parse(e.message)?.data?.originalError?.code
    } catch (e) {
      console.debug('Could not parse error:', e)
      return undefined
    }
  }

  return Number.isNaN(foundCode) ? undefined : parseInt(foundCode)
}

async function setNetworkOrAddIfNotExistsAsync(
  provider: Web3Provider,
  selectedChainId: ChainId,
  dispatch: Dispatch<any>,
): Promise<boolean> {
  console.log('Switching chains to:', selectedChainId)
  dispatch(updateIsSettingChainId({ isSettingChainId: true }))
  return provider
    .send('wallet_switchEthereumChain', [{ chainId: `0x${selectedChainId.toString(16)}` }])
    .then(() => {
      console.debug('Chain successfully switched!')
      dispatch(updateChainId({ chainId: selectedChainId }))
      dispatch(updateIsSettingChainId({ isSettingChainId: false }))
      return true
    })
    .catch(async e => {
      console.log('Could not switch chains', e)
      if (parseCodeFromError(e) === UNRECOGNIZED_CHAIN_ID || e?.code === UNRECOGNIZED_CHAIN_ID) {
        console.debug('Chain ID not recognized, adding...')
        const currency = Ether.onChain(selectedChainId)
        const result = await provider
          .send('wallet_addEthereumChain', [
            {
              chainId: `0x${selectedChainId.toString(16)}`,
              chainName: NETWORK_LABELS[selectedChainId],
              iconUrls: [NETWORK_LOGOS[selectedChainId]],
              rpcUrls: CHAIN_ID_TO_URLS_MAP[selectedChainId],
              nativeCurrency: {
                name: currency.name ?? 'Ether',
                symbol: currency.symbol ?? 'ETH',
                decimals: currency.decimals ?? 18,
              },
              blockExplorerUrls: [getEtherscanLink(selectedChainId, '', 'transaction').slice(0, -3)],
            },
          ])
          .then(() => true)
          .catch(e => {
            if (parseCodeFromError(e) !== CANCELLED_REQUEST && e?.code !== CANCELLED_REQUEST) {
              console.error('Error adding chain:', e)
            }
            return false
          })

        dispatch(updateIsSettingChainId({ isSettingChainId: false }))
        return result
      } else {
        if (parseCodeFromError(e) !== CANCELLED_REQUEST && e?.code !== CANCELLED_REQUEST) {
          console.error('Error switching chain:', e)
        }
        dispatch(updateIsSettingChainId({ isSettingChainId: false }))
        return false
      }
    })
}

function useSetChainCallback(wallet: WalletState | undefined): [boolean, (chainId: ChainId) => Promise<boolean>] {
  const currentChainId = useChainId()
  const isSettingChain = useIsSettingChainId()
  const dispatch = useDispatch()
  const callback = useCallback(
    async (selectedChainId: ChainId) => {
      const provider = wallet?.provider ? new Web3Provider(wallet.provider) : undefined
      const network = provider ? await provider.getNetwork() : undefined
      if (provider && network?.chainId !== selectedChainId) {
        return setNetworkOrAddIfNotExistsAsync(provider, selectedChainId, dispatch)
      } else if (!provider && selectedChainId !== currentChainId) {
        dispatch(updateChainId({ chainId: selectedChainId }))
        return true
      } else {
        return true
      }
    },
    [currentChainId, dispatch, wallet],
  )
  return [isSettingChain, callback]
}

export function useActiveWeb3React(): DolomiteWeb3Context {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  const [, error] = useValidChain()
  const chainId = useChainId()
  const [provider, invalidChain] = useMemo(() => {
    let provider: BaseProvider | Web3Provider
    let invalidChain = false
    if (wallet && error?.message !== INVALID_CHAIN) {
      provider = new Web3Provider(wallet.provider)
    } else {
      provider = LIBRARY_MAP[chainId]
      invalidChain = true
    }
    return [provider, invalidChain]
  }, [chainId, error?.message, wallet])

  const [isSettingChain, setChain] = useSetChainCallback(wallet ?? undefined)

  return useMemo(() => {
    return {
      wallet,
      connect,
      disconnect,
      error,
      isSettingChain,
      setChain,
      active: !!wallet,
      library: provider,
      chainId,
      account: !invalidChain && wallet && wallet.accounts[0] ? getAddress(wallet.accounts[0]?.address) : undefined,
    }
  }, [wallet, connect, disconnect, error, isSettingChain, setChain, provider, chainId, invalidChain])
}

export interface ContractCall {
  contract: Contract
  parameters: ContractCallParameters
}

export interface SuccessfulContractCall {
  call: ContractCall
  gasEstimate: BigNumber
}

export interface FailedContractCall {
  call: ContractCall
  error: Error
}

export type EstimatedContractCall = SuccessfulContractCall | FailedContractCall

export async function estimateGasAsync(
  contract: Contract,
  methodName: string,
  args: any[],
  options = {},
): Promise<EstimatedContractCall> {
  const skipVerification = new URLSearchParams(window.location.search).get('skip-tx-verification') === 'true'
  const value = (options as any).value ? (options as any).value : undefined
  const call: ContractCall = {
    contract,
    parameters: {
      methodName,
      args,
      value,
    },
  }

  if (!contract.estimateGas[methodName]) {
    return Promise.reject(new Error(`Invalid method ${methodName} on contract ${contract.address}`))
  }

  return await contract.estimateGas[methodName](...args, options)
    .then<EstimatedContractCall>(gasEstimate => {
      return {
        call,
        gasEstimate,
      }
    })
    .catch<EstimatedContractCall>(gasError => {
      console.debug('Gas estimate failed, trying eth_call to extract error', call, gasError)

      if (gasError.message?.includes('gas required exceeds allowance')) {
        return {
          call,
          error: new Error(USER_ERROR_MESSAGES.INSUFFICIENT_GAS_TOKEN),
        }
      }

      const chainId = store.getState().chain.chainId
      if (skipVerification || chainId === ChainId.POLYGON_ZKEVM || chainId === ChainId.X_LAYER) {
        console.warn('Sending default gas for now...')
        return Promise.resolve({
          call,
          gasEstimate: BigNumber.from(5_000_000),
        })
      }

      return contract.callStatic[methodName](...args, options)
        .then(result => {
          console.debug('Unexpected successful call after failed estimate gas', call, gasError, result)
          return {
            call,
            error: new Error('Unexpected issue with estimating the gas. Please try again.'),
          }
        })
        .catch(callError => {
          console.debug('Call threw error', call, callError)
          const reason = callError?.data?.message ?? callError.message
          let errorMessage: string
          if (
            reason.includes('DolomiteAmmRouterProxy: excessive input amount') ||
            reason.includes('DolomiteAmmRouterProxy: insufficient output amount')
          ) {
            errorMessage =
              'This transaction will not succeed because of an unfavorable price movement. Try again after a few seconds or increase your slippage tolerance.'
          } else if (reason.toLowerCase().includes('undercollateralized account')) {
            // TODO separate difference between this and when cross margin is enabled and the user going under collateralized.
            errorMessage =
              'This transaction will not succeed because your balance will go negative. This happens when cross margin is disabled and you submit a transaction before your balance updates. Try again after a few seconds.'
          } else if (reason.toLowerCase().includes('account cannot go negative')) {
            errorMessage =
              'This transaction will not succeed because your balance will go negative. This happens when cross margin is disabled and you submit a transaction before your balance updates. Try again after a few seconds.'
          } else {
            if (reason) {
              errorMessage = `The transaction will not succeed due to error: ${reason}. Try again after a few seconds.`
            } else {
              errorMessage = `The transaction will not succeed due to an unknown error. Try again after a few seconds.`
            }
          }
          return {
            call,
            error: new Error(errorMessage),
          }
        })
    })
}
